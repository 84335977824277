import introspectionSlider from './components/introspectionSlider';
import faqToggle from './components/faqToggle';
import quantityButton from './components/quantityButton';
import shippingOption from './components/shippingOption';
import zip from './components/zip';
import courseSlider from './components/courseSlider';
import formvalidation from './components/formValidation';

document.addEventListener(
  'DOMContentLoaded',
  () => {
    introspectionSlider();
    faqToggle();
    quantityButton();
    shippingOption();
    courseSlider();
    zip();
    formvalidation();
  },
  false
);
