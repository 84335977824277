//@ts-nocheck
/**
 * slider for course detail page
 */
const courseSlider = () => {
  const images = document.querySelectorAll('.js-course-image');
  const modals = document.querySelectorAll('.js-course-modal');
  const closes = document.querySelectorAll('.js-close');

  images.forEach((image, index) => {
    const modal = modals[index];
    const close = closes[index];

    image.addEventListener('click', () => {
      modal.classList.add('active');
    });

    close.addEventListener('click', () => {
      modal.classList.remove('active');
    });
  });
};

export default courseSlider;