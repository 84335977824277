/**
 * Input Increment and Decrement
 */
const inputNumber = () => {
  const input = document.querySelectorAll('.js-quantity') as NodeListOf<HTMLInputElement>;
  const incre = document.querySelectorAll('.js-increment') as NodeListOf<HTMLInputElement>;
  const decre = document.querySelectorAll('.js-decrement') as NodeListOf<HTMLInputElement>;

  incre.forEach((increment, index) => {
    const decrement = decre[index];
    const inputField = input[index];

    increment.addEventListener('click', () => {
      const currentValue = parseInt(inputField.value);
      inputField.value = (currentValue + 1).toString();

      if (inputField.value === '1') {
        increment.disabled = true;
      }
      decrement.disabled = false;
    });

    decrement.addEventListener('click', () => {
      const currentValue = parseInt(inputField.value);
      if (currentValue > 1) {
        inputField.value = (currentValue - 1).toString();
      }

      if (inputField.value === '1') {
        decrement.disabled = true;
      }
      increment.disabled = false;
    });
  });
};

export default inputNumber;