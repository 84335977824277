/**
 * Show and hide shipping option
 */
const shippingOption = () => {
  const show = document.querySelector('.js-show');
  const ship = document.querySelector('.js-shipping-show') as HTMLInputElement;
  const hide = document.querySelector('.js-shipping-hide') as HTMLInputElement;

  if (ship) {
    ship.addEventListener('change', () => {
      if (ship.checked) {
        show?.classList.add('show');
      }
    });
  }

  if (hide) {
    hide.addEventListener('change', () => {
      if (hide.checked) {
        show?.classList.remove('show');
      }
    });
  }
};

export default shippingOption;