/**
*** Check and shows if there is error/invalid input on contact form;
**/

const formValidation = () => {
  const name1 = document.querySelector('.js-name-1') as any;
  const name2 = document.querySelector('.js-name-2') as any;
  const email = document.querySelector('.js-email') as any;
  const emailConfirm = document.querySelector('.js-email-confirm') as any;
  const tel = document.querySelector('.js-tel') as any;
  const error = document.querySelector('.js-error') as any;
  const btn = document.querySelector('.js-form-button') as HTMLButtonElement;
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  if (btn) {
    btn.addEventListener('click', (e)=>{
      let errorMessages = [];

      if (name1.value === '' && name2.value === '') {
        errorMessages.push('名前を入力してください。');
      }
      if (email.value === '' || !emailRegex.test(email.value)) {
        errorMessages.push('メールアドレスを入力してください。');
      }
      if (tel.value === '') {
        errorMessages.push('電話番号を入力してください。');
      }
      if (emailConfirm.value === '' || email.value !== emailConfirm.value) {
        errorMessages.push('メールアドレスが一致しません。');
      }

      if (errorMessages.length > 0) {
        e.preventDefault();
        error.innerHTML = errorMessages.join('<br>');
        error.style.scrollMarginTop = '120px';
        error.scrollIntoView({ behavior: 'smooth' });
      } else {
        error.textContent = ''; 
      }
    });
  }
};

export default formValidation;