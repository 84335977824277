/**
 * Initilize Zip code
 */
const zip = () => {
  const cartZip = document.querySelector('.js-cart-zip');

  if (cartZip) {
    cartZip.addEventListener('change', ()=> {
      //@ts-ignore
      AjaxZip3.zip2addr('zip', '', 'address', 'address');
    });
  }
};

export default zip;