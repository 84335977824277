//@ts-nocheck
/**
 * slider for introspection page
 */
 const introspectionSlider = () => {
  const sliderMain = document.querySelector('.js-slidermain');
  const sliderNav = document.querySelector('.js-slidernav');

  if (sliderMain && sliderNav) {
    let gallerySlide = new Splide( '.js-slidermain',
      {
        type: 'loop',
        perPage: 1,
        pagination: false,
        arrows: false,
      } );

    let galleryNav = new Splide( '.js-slidernav',
      {
        rewind: true,
        pagination: false,
        perPage: 4,
        isNavigation: true,
        gap: 12,
      });

    if (gallerySlide && galleryNav) {
      gallerySlide.sync( galleryNav);
      gallerySlide.mount();
      galleryNav.mount();
    }
  }
};

export default introspectionSlider;