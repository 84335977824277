/**
 * FAQ
 */
const faqToggle = () => {
  const faqQuestion = document.querySelectorAll('.faq__question');

  if (faqQuestion) {
    for (let i = 0; i < faqQuestion.length; i++) {
      faqQuestion[i].addEventListener('click', () => {
        faqQuestion[i].classList.toggle('is-active');
      });
    }
  }
};

export default faqToggle;